.content {
  .form-builder-container {
    form {
      padding-bottom: 100px;
    }
    #edit-submit {
      display: inline-block;
      position: relative;
      height: auto;
    }
  }
}
