.page-sticky-footer .page-sticky-footer__right {
  width: 230px;
  margin-right: 0px;
}

@media only screen and (min-width: 641px) {
  .footer-legal .menu li {
    padding-right: 35px;
  }
}

.footer-legal .menu li:nth-child(3n + 3) {
  padding-right: 35px;
}
